export class CustomerInformation {
    injuredWorkerAddressCity!: string;
    injuredWorkerAddressLine1!: string;
    injuredWorkerAddressLine2!: string;
    injuredWorkerAddressState!: string;
    injuredWorkerAddressZip!: string;
    injuredWorkerDateOfBirth!: string;
    injuredWorkerEmail!: string;
    injuredWorkerFirstName!: string;
    injuredWorkerLastName!: string;
    injuredWorkerPhone!: string;
    injuredWorkerSSN!: string;
    reporterInjuredWorker!: boolean;
    reporterEmail!: string;
    reporterFirstName!: string;
    reporterLastName!: string;
    reporterPhone!: string;
    injuredWorkerDateOfEnrollment!: string;
    reporterRelationshipToInjuredWorker!: string;
}