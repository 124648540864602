import {CustomerInformation} from "@/components/models/CustomerInformation";
import {AccidentInformation} from "@/components/models/AccidentInformation";
import {AdditionalInformation} from "@/components/models/AdditionalInformation";
import {Status} from "@/components/models/typelists/Status";
import {Contacts} from "@/components/models/Contacts";
import {CommonData} from "@/components/models/CommonData";

export class FnolSubmission {
  submissionId!: string;
  reportNumber!: string;
  status!: Status
  commonData: CommonData = new CommonData()
  customerInformation: CustomerInformation = new CustomerInformation()
  accidentInformation: AccidentInformation = new AccidentInformation()
  additionalInformation: AdditionalInformation = new AdditionalInformation()
  contacts: Contacts = new Contacts()
  accountName!: string;
}