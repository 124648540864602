import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
            light: {
                primary: '#035489',
                secondary: colors.blue.darken2,
                accent: '#035489',
            },
        },
    },
    iconfont: 'mdi'
});
