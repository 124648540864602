<template>
  <v-app>
    <!-- Sizes your content based upon application components -->
    <v-content>
      <v-container>
        <Header></Header>
        <MobileStepper class="hidden-md-and-up"></MobileStepper>
        <ProgressBar></ProgressBar>
      </v-container>
      <v-container class="pb-0">
        <router-view></router-view>
      </v-container>
      <v-container>
        <Footer></Footer>
      </v-container>
    </v-content>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import Header from './views/header.vue';
import Footer from './views/footer.vue';
import MobileStepper from "@/views/components/mobile-stepper.vue";
import ProgressBar from "@/views/components/progress-bar.vue";

@Component({
  components: {
    ProgressBar,
    MobileStepper,
    Header,
    Footer
  }
})
export default class App extends Vue {

}
</script>