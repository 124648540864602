var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.$route.name === 'customer-information'
          || this.$route.name === 'accident-information'
          || this.$route.name === 'contacts'
          || this.$route.name === 'additional-information')?_c('v-row',{staticClass:"d-flex justify-center",attrs:{"data-cy":"progress-bar"}},[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"12","xl":"8","data-cy":"stepper-col"}},[_c('v-stepper',{staticClass:"stepper-background",attrs:{"alt-labels":"","data-cy":"stepper"}},[_c('v-stepper-header',{attrs:{"data-cy":"stepper-header"}},[_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"customerInfoStep","step":"","editable":this.$route.name === 'customer-information',"edit-icon":"","complete":"","color":this.$route.name === 'customer-information' ? '#036fe6' : '#036fe6'}},[_c('v-label',{staticClass:"pt-4"},[_vm._v("Customer Information")])],1),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"accidentInfoStep","step":"","editable":this.$route.name === 'accident-information',"edit-icon":"","complete":this.$route.name === 'accident-information'
                  || this.$route.name === 'contacts'
                  || this.$route.name === 'additional-information',"color":this.$route.name === 'customer-information' ? '#ffffff' : this.$route.name === 'accident-information' ? '#036fe6' : '#036fe6'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-storage-select-label"}},[_vm._v("Accident Information")])],1),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"contactsStep","step":"","editable":this.$route.name === 'contacts',"edit-icon":"","complete":this.$route.name === 'contacts'
                  || this.$route.name === 'additional-information',"color":this.$route.name === 'customer-information'
                  || this.$route.name === 'accident-information' ? '#ffffff' : this.$route.name === 'contacts' ? '#036fe6' : '#036fe6'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-contacts-label"}},[_vm._v("Contacts")])],1),_c('v-stepper-step',{staticClass:"progressBarPage",attrs:{"data-cy":"additionalInformationStep","step":"","editable":_vm.$route.name === 'additional-information',"edit-icon":"","complete":this.$route.name === 'additional-information',"color":this.$route.name === 'customer-information'
                  || this.$route.name === 'accident-information'
                  || this.$route.name === 'contacts'
              ? '#ffffff' : this.$route.name === 'additional-information' ? '#036fe6' : '#036fe6'}},[_c('v-label',{staticClass:"pt-4",attrs:{"data-cy":"progress-bar-add-information-label"}},[_vm._v("Additional Information")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }