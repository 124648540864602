export class AccidentInformation {
    dateOfInjury!: string;
    timeOfInjury!: string;
    injuredWorkerDeceased!: boolean;
    accidentLocationAddressLine1!: string;
    accidentLocationAddressLine2!: string;
    accidentLocationAddressCity!: string;
    accidentLocationAddressState!: string;
    accidentLocationAddressZip!: string;
    accidentDescription!: string;
    injuryDescription!: string;
    medicalTreatmentReceived!: boolean;
    workingAtTimeOfAccident!: boolean;
    lostTimeFromWork!: boolean;
    accidentReportedToPolice!: boolean;
}