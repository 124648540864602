<template>
  <v-row justify="center">
    <v-col cols="12" xl="8" class="pa-0" data-cy="footer-vue">
      <v-form class="pa-10 pt-0">
        <v-divider class="d-none d-sm-block"></v-divider>
        <v-row>
          <v-col cols="3" sm="3" class="d-sm-none line-container">
            <hr width="80%" class="short-line">
          </v-col>
          <v-col cols="6" sm="3" class="text-center text-lg-left">
            <img width="100%" alt="GAIG Logo" src="../assets/logo-gaig.svg"
                 data-cy="logo-footer" style="display: inline-block">
          </v-col>
          <v-col cols="3" sm="3" class="d-sm-none line-container" >
            <hr width="80%" class="short-line">
          </v-col>

          <v-col cols="12" md="4">
            <div class="frozen-footer" data-cy="about-us">About</div>
            <div>
              <label><a href="https://www.greatamericaninsurancegroup.com/contact/legal-disclosures"
                        target="_blank" data-cy="legal-disclosures">
                <span> Legal Disclosures / Terms and Conditions</span> </a></label>
            </div>
            <div>
              <label><a
                href="https://www.greatamericaninsurancegroup.com/docs/default-source/default-document-library/gaig-pcservice-marks.pdf"
                target="_blank" data-cy="service-marks"><span>Service Marks</span></a></label>
            </div>
            <div>
              <label><a href="https://www.greatamericaninsurancegroup.com/contact/privacy" target="_blank"
                        data-cy="privacy"><span>Privacy</span></a></label>
            </div>
            <div>
              <label><a href="https://www.greatamericaninsurancegroup.com/contact/privacy/cookie-policy"
                        target="_blank" data-cy="cookie-policy"><span> Cookie Policy</span></a></label>
            </div>
            <div>
              <label><a href="https://www.greatamericaninsurancegroup.com/contact/accessibility" target="_blank"
                        data-cy="accessibility"><span>Accessibility</span></a></label>
            </div>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
              <div class="frozen-footer" data-cy="contact-us">Contact Us</div>
              <div data-cy="contact-phone" class="contact-phone">(833) 444-0161</div>
              <div data-cy="contact-email" class="contact-email">ondemandclaims@gaig.com</div>
            </v-col>
        </v-row>
        <div style="height:2em"></div>
        <v-row justify="center">
          <p data-cy="copy-right">©{{new Date().getFullYear().toString()}} Great American Insurance Company. All Rights Reserved.</p>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {DateUtils} from "@/components/utils/date-utils";

@Component
  export default class Footer extends Vue {

  dateUtils = new DateUtils()
  }


</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0;
    font-weight: normal !important;
    color: #777777;
  }

  .frozen-footer {
    font-weight: bold !important;
    color: #555555;
  }

  .link {
    color: #337ab7 !important;
  }

  .footerStyling {
    margin-top: 15px;
  }

  .short-line{
    stroke:rgb(224,224,224);
    stroke-width:2;
    position: absolute;
    top: 50%;
  }

  .line-container{
    position: relative;
  }

  .svg-size{
    height:36px;
    width:20%;
  }

  .contact-phone {
    color: #424242;
  }

  .contact-email {
    color: #036fe6;
  }

</style>
