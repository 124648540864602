<template>
  <v-row class="pa-0 mt-n4" data-cy="mobile-stepper"
         v-if="this.$route.name === 'customer-information'
            || this.$route.name === 'accident-information'
            || this.$route.name === 'contacts'
            || this.$route.name === 'additional-information'">
    <v-col class="pa-0" cols="12">
      <v-card
        class="mx-auto"
        color="#003366"
        height="70px">
        <v-list-item two-line class="mt-n2">
          <v-list-item-content class="pa-0 mt-n2">
            <v-list-item-title class="stepper-title" data-cy="mobile-stepper-current-page">
              {{ $route.name === 'customer-information' ? 'Customer Information' :
              $route.name === 'accident-information' ? 'Accident Information' :
              $route.name === 'contacts' ? 'Contacts' :
              $route.name === 'additional-information' ? 'Additional Information' : '' }}
            </v-list-item-title>
            <v-list-item-subtitle class="stepper-subtitle pt-2" data-cy="mobile-stepper-next-page">
              {{ $route.name === 'customer-information' ? 'Next: Accident Information' :
              $route.name === 'accident-information' ? 'Next: Contacts' :
              $route.name === 'contacts' ? 'Next: Additional Information' :
              $route.name === 'additional-information' ? '' : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar
            size="60"
            color="grey"
            class="mt-1">
            <v-progress-circular
              :rotate="-90"
              :size="60"
              :width="5"
              :value="$route.name === 'customer-information' ? 25 :
              $route.name === 'accident-information' ? 50 :
              $route.name === 'contacts' ? 75 :
              $route.name === 'additional-information' ? 100 : 0"
              color="green">
              <span class="stepper-numbers" data-cy="mobile-stepper-page-number">
                {{ $route.name === 'customer-information' ? '1 of 4' :
              $route.name === 'accident-information' ? '2 of 4' :
              $route.name === 'contacts' ? '3 of 4' :
              $route.name === 'additional-information' ? '4 of 4' : '' }}
              </span>
            </v-progress-circular>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">

  import Component from "vue-class-component";
  import {Vue} from "vue-property-decorator";

  @Component
  export default class MobileStepper extends Vue {

  }
</script>

<style>

.stepper-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.stepper-numbers {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.stepper-subtitle {
  color: #ffffff !important;
}

</style>