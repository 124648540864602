import { render, staticRenderFns } from "./progress-bar.vue?vue&type=template&id=2472af11&scoped=true"
import script from "./progress-bar.vue?vue&type=script&lang=ts"
export * from "./progress-bar.vue?vue&type=script&lang=ts"
import style0 from "./progress-bar.vue?vue&type=style&index=0&id=2472af11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2472af11",
  null
  
)

export default component.exports