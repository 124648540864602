<template>
  <v-row justify="center">
    <v-col cols="12" xl="8" class="pb-0 pt-0" data-cy="header-vue">
      <v-row>
        <v-col cols="3" xl="3">
          <img alt="GAIG Logo" src="../assets/logo-gaig.svg" data-cy="company-header-logo">
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row justify="space-between">
        <v-col cols="12" class="pb-0 align-self-end">
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</template>

<script lang="ts">

  import {Component, Vue} from "vue-property-decorator";


  @Component
  export default class Header extends Vue {}
</script>

<style lang="scss" scoped>

</style>
