import {Vue} from "vue-property-decorator";
import moment from "moment"

export class DateUtils extends Vue {

  formatDateForDisplay(date: string): string {
    return date ? moment(date, ["YYYY-MM-DD", "MM/DD/YYYY"]).format('MM/DD/YYYY') : '';
  }

  formatDateForDatePicker(date: string): string {
    return date ? moment(date, ["YYYY-MM-DD", "MM/DD/YYYY"]).format('YYYY-MM-DD') : '';
  }

  formatTimeForDisplay(time: string): string {
    return time ? moment(time).format('LT') : ''
  }

  formatTimeAMPMForDisplay(time: string): string {
    return time ? moment(time, "HH:mm").format('h:mm A') : ''
  }

  trimSecFromTime(time: string): string{
    if(time != null){
      return time.substr(0,5);
    }
  }

}