var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.$route.name === 'customer-information'
          || this.$route.name === 'accident-information'
          || this.$route.name === 'contacts'
          || this.$route.name === 'additional-information')?_c('v-row',{staticClass:"pa-0 mt-n4",attrs:{"data-cy":"mobile-stepper"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#003366","height":"70px"}},[_c('v-list-item',{staticClass:"mt-n2",attrs:{"two-line":""}},[_c('v-list-item-content',{staticClass:"pa-0 mt-n2"},[_c('v-list-item-title',{staticClass:"stepper-title",attrs:{"data-cy":"mobile-stepper-current-page"}},[_vm._v(" "+_vm._s(_vm.$route.name === 'customer-information' ? 'Customer Information' : _vm.$route.name === 'accident-information' ? 'Accident Information' : _vm.$route.name === 'contacts' ? 'Contacts' : _vm.$route.name === 'additional-information' ? 'Additional Information' : '')+" ")]),_c('v-list-item-subtitle',{staticClass:"stepper-subtitle pt-2",attrs:{"data-cy":"mobile-stepper-next-page"}},[_vm._v(" "+_vm._s(_vm.$route.name === 'customer-information' ? 'Next: Accident Information' : _vm.$route.name === 'accident-information' ? 'Next: Contacts' : _vm.$route.name === 'contacts' ? 'Next: Additional Information' : _vm.$route.name === 'additional-information' ? '' : '')+" ")])],1),_c('v-list-item-avatar',{staticClass:"mt-1",attrs:{"size":"60","color":"grey"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":60,"width":5,"value":_vm.$route.name === 'customer-information' ? 25 :
            _vm.$route.name === 'accident-information' ? 50 :
            _vm.$route.name === 'contacts' ? 75 :
            _vm.$route.name === 'additional-information' ? 100 : 0,"color":"green"}},[_c('span',{staticClass:"stepper-numbers",attrs:{"data-cy":"mobile-stepper-page-number"}},[_vm._v(" "+_vm._s(_vm.$route.name === 'customer-information' ? '1 of 4' : _vm.$route.name === 'accident-information' ? '2 of 4' : _vm.$route.name === 'contacts' ? '3 of 4' : _vm.$route.name === 'additional-information' ? '4 of 4' : '')+" ")])])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }