<template>
  <v-row data-cy="progress-bar" class="d-flex justify-center"
         v-if="this.$route.name === 'customer-information'
            || this.$route.name === 'accident-information'
            || this.$route.name === 'contacts'
            || this.$route.name === 'additional-information'">
    <v-col cols="12" xl="8" class="hidden-sm-and-down" data-cy="stepper-col">
      <v-stepper alt-labels class="stepper-background" data-cy="stepper">
        <v-stepper-header data-cy="stepper-header">
          <v-stepper-step
              data-cy="customerInfoStep"
              step=""
              :editable="this.$route.name === 'customer-information'"
              edit-icon=""
              complete
              :color="this.$route.name === 'customer-information' ? '#036fe6' : '#036fe6'"
              class="progressBarPage">
            <v-label class="pt-4">Customer Information</v-label>
          </v-stepper-step>
          <v-stepper-step
              data-cy="accidentInfoStep"
              step=""
              :editable="this.$route.name === 'accident-information'"
              edit-icon=""
              :complete="this.$route.name === 'accident-information'
                    || this.$route.name === 'contacts'
                    || this.$route.name === 'additional-information'"
              :color="this.$route.name === 'customer-information' ? '#ffffff' : this.$route.name === 'accident-information' ? '#036fe6' : '#036fe6'"
              class="progressBarPage">
            <v-label data-cy="progress-bar-storage-select-label" class="pt-4">Accident Information</v-label>
          </v-stepper-step>
          <v-stepper-step
              data-cy="contactsStep"
              step=""
              :editable="this.$route.name === 'contacts'"
              edit-icon=""
              :complete="this.$route.name === 'contacts'
                    || this.$route.name === 'additional-information'"
              :color="this.$route.name === 'customer-information'
                    || this.$route.name === 'accident-information' ? '#ffffff' : this.$route.name === 'contacts' ? '#036fe6' : '#036fe6'"
              class="progressBarPage">
            <v-label data-cy="progress-bar-contacts-label" class="pt-4">Contacts</v-label>
          </v-stepper-step>
          <v-stepper-step
              data-cy="additionalInformationStep"
              step=""
              :editable="$route.name === 'additional-information'"
              edit-icon=""
              :complete="this.$route.name === 'additional-information'"
              :color="this.$route.name === 'customer-information'
                    || this.$route.name === 'accident-information'
                    || this.$route.name === 'contacts'
                ? '#ffffff' : this.$route.name === 'additional-information' ? '#036fe6' : '#036fe6'"
              class="progressBarPage">
            <v-label data-cy="progress-bar-add-information-label" class="pt-4">Additional Information</v-label>
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script lang="ts">

import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {

}
</script>

<style lang="scss" scoped>
.stepper-background {
  background-color: #ffffff;
}

.v-label {
  color: #003366 !important;
}

.progressBarPage {
  pointer-events: none;
  flex-basis: auto;
  padding-left: 10px;
  padding-right: 10px;
}

</style>