import Vue from 'vue';
import Vuex from 'vuex';
import {FnolSubmission} from '@/components/models/FnolSubmission';
import {FnolService} from '@/services/fnol-service';
import {ContactType} from "@/components/models/typelists/ContactType";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accountName: String,
    submission: FnolSubmission,
    showContactForm: false,
    contactTypeBeingEditedOnContactForm: ContactType,
    showContactDeleteModal: false,
    showContactActionModal: false,
    showCancelClaimModal: false,
    siteKey: String,
    isRecaptchaTokenValid: false,
    ssValidationError:false
  },
  actions: {
    async getKey({commit}){
      const service = new FnolService()
      await service.getKey().then(response => {
        commit('setKey', response)
      });
    },
    async verifyToken({commit}, token: string){
      const service = new FnolService()
      return service.isTokenValid(token).then(response => {
        commit('setIsRecaptchaTokenValid', response)
      });
    },
    async createNewSubmission({commit}, accountName) {
      const service = new FnolService();
      await service.createNewSubmission(accountName).then(submission => {
        const initializedSubmission = service.initializeSubmission(submission)
        commit('setSubmission', initializedSubmission)
      });
    },
    async saveSubmission({commit}, submission: FnolSubmission) {
      const service = new FnolService();
      return await service.saveFnol(submission).then(submission => {
        if(submission.submissionId === "ERROR"){
          console.log("ERROR, THERE IS A BACKEND VALIDATION ERROR")
          commit("setValidationError", submission)
        }else{
          const initializedSubmission = service.initializeSubmission(submission)
          console.log("all is well.  nothing is ruined.")
          commit('setSubmission', initializedSubmission);
        }
      });
    }
  },
  mutations: {
    setSubmission(state, submission) {
      state.submission = submission
      state.ssValidationError = false;
    },
    setValidationError(state, submission){
      state.ssValidationError = true;
    },
    setShowCancelClaimModal(state, showModal: boolean) {
      state.showCancelClaimModal = showModal
    },
    setShowContactForm(state, showForm: boolean) {
      state.showContactForm = showForm
    },
    setShowContactDeleteModal(state, showModal: boolean) {
      state.showContactDeleteModal = showModal
    },
    setShowContactActionModal(state, showModal: boolean) {
      state.showContactActionModal = showModal
    },
    setContactTypeBeingEditedOnContactForm(state, contactType) {
      state.contactTypeBeingEditedOnContactForm = contactType
    },
    setKey(state, response) {
      state.siteKey = response;
    },
    setIsRecaptchaTokenValid(state, response) {
      state.isRecaptchaTokenValid = response;
    },
    setAccountName(state, accountName) {
      state.accountName = accountName;
    }
  }
})
