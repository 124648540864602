import {FnolSubmission} from '@/components/models/FnolSubmission'
import {Vue} from 'vue-property-decorator'
import axiosInstance from "@/components/utils/axios-instance";



export class FnolApi extends Vue {

  async getKey(): Promise<Record<string, any>> {
    //This is only here to get an XSRF token for the recaptcha key
    await axiosInstance.request<Record<string, any>>({
      method: 'get',
      url: '/isInternal',
      responseType: 'json'
    });

    const response = await axiosInstance.request<Record<string, any>>({
      method: 'post',
      url: '/recaptcha/key',
      responseType: 'json'
    });
    return response.data;
  }

  async isTokenValid(token: string): Promise<boolean> {
    const response = await axiosInstance.request<boolean>({
      method: 'post',
      url: '/recaptcha/verify',
      data: token,
      responseType: 'json',
    });
    return response.data;
  }

  async createSubmission(fnolSubmission: FnolSubmission): Promise<FnolSubmission> {
    const response = await axiosInstance.request<FnolSubmission>({
      method: 'post',
      url: '/fnol/create',
      data: fnolSubmission,
      responseType: 'json',
    });
    return response.data;
  }

  async updateSubmission(fnolSubmission: FnolSubmission): Promise<FnolSubmission> {
    const response = await axiosInstance.request<FnolSubmission>({
      method: 'put',
      url: '/fnol/update',
      data: fnolSubmission,
      responseType: 'json',
    });
    return response.data;
  }
}
