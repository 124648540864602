import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "landing-page",
    props: (route) => ({ account: route.query.account}),
    component: () =>
        import(/* webpackChunkName: "landing-page" */ "../views/landing-page.vue")
  },
  {
    path: "/access-agreement",
    name: "access-agreement",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "access-agreement" */ "../views/access-agreement.vue")
  },
  {
    path: "/customer-information",
    name: "customer-information",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "customer-information" */ "../views/customer-information.vue")
  },
  {
    path: "/accident-information",
    name: "accident-information",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "accident-information" */ "../views/accident-information.vue")
  },
  {
    path: "/contacts",
    name: "contacts",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "contacts" */ "../views/contacts.vue")
  },
  {
    path: "/additional-information",
    name: "additional-information",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "additional-information" */ "../views/additional-information.vue")
  },
  {
    path: "/review-and-submit",
    name: "review-and-submit",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "review-and-submit" */ "../views/review-and-submit.vue")
  },
  {
    path: "/confirmation",
    name: "confirmation",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "confirmation" */ "../views/confirmation.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
