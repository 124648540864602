import {Contact} from "@/components/models/Contact";

export class Contacts {
    medicalProviderDoctors!: Array<Contact>;
    witnesses!: Array<Contact>;
    authoritiesContacted!: Array<Contact>;
    otherContacts!: Array<Contact>;
    claimPointOfContact!: string;
    otherPointOfContactEmail!: string;
    otherPointOfContactFirstName!: string;
    otherPointOfContactLastName!: string;
    otherPointOfContactPhone!: string;
    otherPointOfContactRelationshipToInjuredWorker!: string;

}