import {Vue} from 'vue-property-decorator'
import {FnolSubmission} from "@/components/models/FnolSubmission";
import {FnolApi} from "@/services/backend-api";
import {AccidentInformation} from "@/components/models/AccidentInformation";
import {AdditionalInformation} from "@/components/models/AdditionalInformation";
import {CustomerInformation} from "@/components/models/CustomerInformation";
import {Contacts} from "@/components/models/Contacts";
import {Contact} from "@/components/models/Contact";

export class FnolService extends Vue {

  api = new FnolApi();

  async getKey(): Promise<Record<string, any>> {
    return await this.api.getKey();
  }

  async isTokenValid(token: string): Promise<boolean> {
    return await this.api.isTokenValid(token);
  }

  async createNewSubmission(accountName: string): Promise<FnolSubmission> {
    let data: FnolSubmission = new FnolSubmission();
    const newSubmission = new FnolSubmission();
    newSubmission.accountName = accountName;
    await this.api.createSubmission(newSubmission).then(value => {
      data = value;
    })
    return data;
  }

  async saveFnol(fnol: FnolSubmission): Promise<FnolSubmission> {
    let data: FnolSubmission = new FnolSubmission();
      await this.api.updateSubmission(fnol).then(value => {
        data = value;
    })
    return data;
  }

  initializeSubmission(submission: FnolSubmission): FnolSubmission {
    if (!submission.accidentInformation) {
      submission.accidentInformation = new AccidentInformation();
    }
    if (!submission.additionalInformation) {
      submission.additionalInformation = new AdditionalInformation();
    }
    if (!submission.customerInformation) {
      submission.customerInformation = new CustomerInformation();
    }
    if (!submission.contacts) {
      submission.contacts = new Contacts();
    }
    if (!submission.contacts.medicalProviderDoctors) {
      submission.contacts.medicalProviderDoctors = new Array<Contact>()
    }
    if (!submission.contacts.witnesses) {
      submission.contacts.witnesses = new Array<Contact>()
    }
    if (!submission.contacts.authoritiesContacted) {
      submission.contacts.authoritiesContacted = new Array<Contact>()
    }
    if (!submission.contacts.otherContacts) {
      submission.contacts.otherContacts = new Array<Contact>()
    }
    return submission;
  }

}